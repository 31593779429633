@import url(https://fonts.googleapis.com/css?family=Chilanka&display=swap);
:root {
  --primary: #137c95;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  background: #eeeeee91;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header,
footer {
  background: #444444f5; /* #616161e3; */
  color: #eee;
}

.brand {
  display: flex;
  align-items: center;
}

.brand .logo {
  height: 3rem;
  margin-right: 0.5rem;
}

.brand .name {
  font-family: "Chilanka";
  font-size: 2rem;
  padding-top: 0.75rem;
}

main {
  flex: 1 1;
}

footer {
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 3rem;
}

footer > * {
  margin-right: 2rem;
}

footer .github-link {
  display: flex;
  align-items: center;
}

footer .github-link > img {
  width: 1.25rem;
  margin-right: 0.25rem;
}

footer .github-link > span {
  margin-top: -0.25rem;
}

.btn {
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn-primary {
  background: #137c95;
  background: var(--primary);
  color: white;
}

.btn-group {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}

.btn-group > .btn {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

/* responsive */
header,
main,
footer {
  padding: 0.5rem 1rem;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  header,
  main,
  footer {
    padding-left: 10%;
    padding-right: 10%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  header,
  main,
  footer {
    padding-left: 20%;
    padding-right: 20%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

